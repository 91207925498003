//  Bootstrap Custom Overrides

/// Spacing
$spacer: 2rem;

/// Paragraphs
$paragraph-margin-bottom: $spacer / 2;

/// Fonts
$headings-margin-bottom: $spacer / 2;
$headings-font-weight: normal;

/// Grid Columns
$grid-gutter-width: 1rem;

/// Navbar
$navbar-padding-y: ($spacer * 0);
$navbar-padding-x: ($spacer / 4);
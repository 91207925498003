//  Colors
/// Spectrum
$blue: 		#4a5bbd;
$indigo: 	#454c73;
$purple: 	#675585;
$pink: 		#e83e8c;
$red: 		#b53c3c;
$orange: 	#d27034;
$yellow: 	#ffce62;
$green: 	#5a9261; //#009e80;
$teal: 		#37a0a5;
$cyan: 		#00b2c4;

/// Theme Colors
$primary: 	$green;
$secondary: $purple;
$tertiary: 	$indigo;
$success: 	$cyan;
$info: 		$blue;
$warning: 	$orange;
$danger: 	$red;

/// Mix Grayscale
$gray-100: 	mix($gray-100, $tertiary, 98%);
$gray-200: 	mix($gray-200, $tertiary, 95%);
$gray-300: 	mix($gray-300, $tertiary, 90%);
$gray-400: 	mix($gray-400, $tertiary, 80%);
$gray-500: 	mix($gray-500, $tertiary, 70%);
$gray-600: 	mix($gray-600, $tertiary, 80%);
$gray-700: 	mix($gray-700, $tertiary, 90%);
$gray-800: 	mix($gray-800, $tertiary, 95%);
$gray-900: 	mix($gray-900, $tertiary, 98%);
$light: 	$gray-100;
$dark: 		$gray-900;

/// Define grayscale as Sass map
$grays: (
	"100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
	"900": $gray-900
);

/// Define spectrum as Sass map
$colors: (
	"blue":			$blue,
	"indigo":		$indigo,
	"purple":		$purple,
	"pink":			$pink,
	"red":			$red,
	"orange":		$orange,
	"yellow":		$yellow,
	"green":		$green,
	"teal":			$teal,
	"cyan":			$cyan,
	"white":		$white,
	"gray":			$gray-600,
	"gray-dark":	$gray-800,
	"black":		$black
);

/// Define theme colors as Sass map
$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	"tertiary": $tertiary
);

// Create '.bg-color' classes
@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

// Create '.bg-gray-[0–1000]' classes
@each $gray, $value in $grays {
	@include bg-variant(".bg-gray-#{$gray}", $value);
}

$body-bg: $gray-100;
$body-color: $gray-700;
$link-color: $primary;
$link-hover-color: $yellow;

$card-border-color: rgba($black, .05);
$card-cap-bg: $gray-200;
$card-bg: $white;

$font-body: 'nunito';
$font-headings: Georgia, Times, Times New Roman, serif;
$font-family-base: $font-body, $font-family-sans-serif;
$headings-font-family: $font-headings;
// Import custom typography
@import 'custom-typography';

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	border-bottom: 0.05em solid transparent;

	&:hover,
	&:focus {
		text-decoration: none;
		border-bottom-color: currentColor;
	}
}

.icon {
	fill: currentColor;
}

.navbar--custom {
	padding: 0 1rem;
}

.navbar--fill {
	background-color: rgba($gray-900, 0.97);
}

.container--navbar {
}

.navbar-brand--custom {
	width: 10rem;
	padding: 0;

	&:hover,
	&:focus {
		border-bottom-color: transparent;
	}

	@media screen and (max-width: 991px) {
		padding: 1rem 0;
	}
}

.navbar.navbar--custom .nav-link--custom {
	color: rgba($green, 1);
	padding-top: ($spacer / 2);
	padding-bottom: ($spacer / 2);
	border-bottom-width: 0.125rem;
	font-weight: bold;

	&:hover,
	&:focus {
		color: rgba($white, 1);
		border-bottom-color: rgba($yellow, 1);
	}
}

.navbar.navbar--custom .nav-link.active {
	color: rgba($white, 0.9);
	border-bottom-color: rgba($yellow, 0.9);
}

.navbar-nav {
	font-size: 0.8rem;
}

.navbar-toggler.navbar-toggler--custom {
	border-width: 0.1rem;
	border-radius: 0.35rem;
	border-color: $link-color;
	padding: 0.25rem 0.5rem;
	display: flex;
	align-items: stretch;
	margin: 1rem 0;

	&:hover,
	&:focus {
		border-color: $link-hover-color;

		.navbar-toggler--icon {
			@supports (mask-repeat: no-repeat) {
				background-color: $link-hover-color;
			}
		}

		.navbar-toggler--text {
			color: $link-hover-color;
		}
	}

	&[aria-expanded='false'] {
		.navbar-toggler-icon.navbar-toggler--icon {
			//background-color: none;

			@supports (mask-repeat: no-repeat) {
				mask-image: url(../images/icons/menu.svg);
			}
		}
	}

	@media (min-width: 992px) {
		display: none;
	}
}

.navbar-toggler-icon.navbar-toggler--icon {
	width: 0;
	height: 0;
	background-image: none;
	background-color: transparent;

	@supports (mask-repeat: no-repeat) {
		width: 1rem;
		height: 1rem;
		margin-right: 0.125rem;
		background-color: $link-color;
		background-image: none;
		mask-image: url(../images/icons/close.svg);
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: auto 100%;
	}
}

.navbar-toggler--text {
	height: 1rem;
	display: inline-flex;
	align-items: center;
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: bold;
	color: $link-color;
}

.logo {
	width: 100%;
	max-width: 100px;
}

.lead {
	color: $gray-600;
	font-style: italic;
	font-family: $font-headings;
	@include fluid-type($modular_min_width, $modular_max_width, $h4_min, $h4_max);
	margin-bottom: 1rem;
}

.blockquote {
	font-family: $font-headings;
}

.blockquote-footer {
	font-family: $font-family-base;
}

.font--headings {
	font-family: $font-headings;
}

.font--body {
	font-family: $font-family-base;
}

.row--cards {
	padding: 0;
	list-style-type: none;
	justify-content: center;
}

.card--holder {
	margin-bottom: 1rem;
}

.card--main {
	border: 0;
	border-bottom: 0.25rem solid $tertiary;
}

.card-body,
.card-header {
	padding: 3rem;
}

.card--service {
	height: 100%;
	background-color: $white;
}

.icon__holder {
	background-color: $secondary;
	border-radius: 100%;
	width: 3rem;
	height: 3rem;
	margin-right: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
}

.icon__holder--contact {
	background-color: $white;
}

.icon--service {
	fill: $white;
	width: 1.5rem;
	height: 1.5rem;
	z-index: 2;
}

.icon--contact {
	fill: $secondary;
}

.card--link {
	background-color: $white;
	border-radius: $border-radius;
	border-bottom-color: transparent;

	&:hover,
	&:focus {
		color: $white;
		background-color: $primary;
		border-bottom-color: transparent;

		.card-header--link {
			color: $white;
			background-color: transparent;

			&::after {
				@supports (mask-repeat: no-repeat) {
					background-color: currentColor;
				}
			}
		}

		.card-title--link {
			color: currentColor;
		}

		.card-paragraph--link {
			color: currentColor;
		}

		.card-body--link {
			background-color: transparent;
		}

		.icon--contact {
			fill: $primary;
		}

		.icon__holder--contact {
		}
	}
}

.card-header--link {
	background-color: $secondary;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;

	&::after {
		content: '';
		color: $white;
		width: 1em;
		height: 1em;

		background-image: url('../images/icons/open-in-new-white.svg'); // Fallback for browsers that don’t support 'mask' css
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center bottom;

		@supports (mask-repeat: no-repeat) {
			background-color: currentColor;
			background-image: none;
			mask-image: url('../images/icons/open-in-new.svg');
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 100% auto;
		}
	}
}

.card-title--main {
	font-family: $font-family-base;
}

.card-title--link {
	@include fluid-type($modular_min_width, $modular_max_width, $h6_min, $h6_max);
	font-weight: bold;
	color: $white;
	padding: 0;
	margin-bottom: 0;
}

.card-title--color {
	font-weight: bold;
}

.card-body--link {
	padding: 1rem;
	font-weight: normal;
}

.card-body--contact {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $secondary;
	border-radius: $border-radius;
}

.card-paragraph--link {
	color: $gray-600;
	margin: 0;
}

.card-paragraph--contact {
	color: $white;
}

.card--contact {
	border-bottom-width: 0;
}

.card--social {
	@include fluid-type($modular_min_width, $modular_max_width, $h6_min, $h6_max);
	background-color: transparent;
	border: 0.125rem solid;

	&:hover,
	&:focus {
		color: currentColor;
		border-color: currentColor;
		background-color: currentColor;

		.card-body--social {
			color: currentColor;
			border-color: currentColor;
			background-color: transparent;
		}

		.card-paragraph--social {
			color: $white;
		}

		.icon--social {
			fill: $white;
		}
	}
}

.card-body--social {
	background-color: transparent;
	padding: 0.5rem;
	justify-content: center;
}

.icon__holder--social {
	background-color: transparent;
	width: auto;
	height: auto;
	margin-right: 0.5rem;
}

.icon--social {
	fill: currentColor;
}

.card-paragraph--social {
	color: inherit;
}

.card--social--instagram {
	color: #405de6;

	&:hover,
	&:focus {
		color: #405de6;
	}
}

.card--social--download {
	color: $secondary;

	&:hover,
	&:focus {
		color: $secondary;
	}
}

.site__section {
	width: 100%;
	min-height: 40vmax;
	padding: 4rem;
	position: relative;

	&:nth-child(even) {
		background-color: $gray-200;
	}

	@media screen and (max-width: 576px) {
		padding-left: .25rem;
		padding-right: .25rem;
	}
}

.site__section.site__section--about {
	padding-top: 0;
	padding-bottom: 0;
	position: relative;
	background-color: $gray-700;

	&::before,
	&::after {
		position: absolute;
		content: '';
		background-color: $gray-700;
		left: 0;
		right: 0;
	}

	&::before {
		background-color: $gray-800;
		bottom: calc(100% - 2rem);
		top: 0;
	}

	&::after {
		background-color: $gray-200;
		top: calc(100% - 4rem);
		bottom: 0;
	}
}

.card--about {
	z-index: 1;
	margin-bottom: 2rem;
}

.site__section--home {
	position: relative;
	padding: 0;
	background-color: #434356;

	&:nth-child(even) {
		background-color: #434356;
	}

	&::before {
		content: '';
		background-color: #414154;
		background-image: linear-gradient(to top, #434356, #232338);
		display: block;
	}
}

.site__section.site__section--power {
}

.container--home {
	display: flex;
	justify-content: center;
	padding: 2rem 0;
}

.container--home-holder {
	background-color: $gray-800;
	overflow: hidden;
	padding: 0 0.75rem;
}

.container--background-holder {
	height: 50vw;
	min-height: 25vmax;
	max-height: 75vmin;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 991px) {
		min-height: calc(25vmax + 4rem);
	}
}

.container--background {
	background-image: url('../images/artwork/banner.svg');
	background-size: 101% auto;
	background-repeat: no-repeat;
	background-position: center bottom;
	padding: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.site__header--h1,
.site__header--h2,
.site__header--h3,
.site__header--h4,
.site__header--h5,
.site__header--h6 {
	color: $gray-700;
	font-weight: normal;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 1em;
	padding-bottom: 0.5em;
	border-bottom: 0.0125rem solid rgba($black, 0.25);

	&::after {
		width: 1em;
		height: 1em;
		content: '';
		margin-left: 0.25em;
		background-image: url('../images/icons/logo-green.svg'); // Fallback for browsers that don’t support 'mask' css
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center bottom;

		@supports (mask-repeat: no-repeat) {
			background-color: $primary;
			background-image: none;
			mask-image: url('../images/icons/logo.svg');
			mask-repeat: no-repeat;
			mask-size: 100% 100%;
			mask-position: center bottom;
		}
	}
}

.site__header--h2 {
	&::before {
		background-color: $primary;
	}
}

.site__header--h3,
.site__header--h4,
.site__header--h5,
.site__header--h6 {
	&::before {
		background-color: $tertiary;
	}
}

.row--home {
	width: 100%;
}

.site__header--home {
	@include fluid-type($modular_min_width, $modular_max_width, $h5_min, $h5_max);
	color: $white;
	margin: 0;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
	border-bottom: none;
	width: 100%;

	&::after {
		display: none;
	}
}

.site__header--minor {
	color: rgba($yellow, 0.85);
	font-size: 0.65em;
	font-family: $font-family-base;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before,
	&::after {
		font-size: 1rem;
		content: '';
		width: 100%;
		flex-basis: 25%;
		flex-grow: 1;
		height: 1px;
		background-color: rgba($secondary, 0.45);
	}

	&::before {
		margin-right: 0.5rem;
	}

	&::after {
		margin-left: 0.5rem;
	}
}

.site__header--major {
	color: $white;
	font-size: 2em;
	font-style: italic;
	margin: 0.75rem 0;
}

.site__header--icon-holder {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before,
	&::after {
		font-size: 1rem;
		content: '';
		width: 100%;
		flex-basis: 25%;
		flex-grow: 1;
		height: 1px;
		background-color: rgba($secondary, 0.45);
	}

	&::before {
		margin-right: 0.5rem;
	}

	&::after {
		margin-left: 0.5rem;
	}
}

.site__header--icon {
	width: 0.85em;
	height: 0.85em;
	background-image: url('../images/icons/logo-yellow.svg'); // Fallback for browsers that don’t support 'mask' css
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center bottom;

	@supports (mask-repeat: no-repeat) {
		background-color: $yellow;
		background-image: none;
		mask-image: url('../images/icons/logo.svg');
		mask-repeat: no-repeat;
		mask-size: 100% 100%;
		mask-position: center bottom;
	}
}

.site__header--service {
	align-items: center;
	justify-content: flex-start;
	position: relative;
	@include fluid-type($modular_min_width, $modular_max_width, $h4_min, $h4_max);

	&::after {
		display: none;
	}
}

.site__gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0;
	list-style-type: none;
}

.site__gallery__item {
	width: 100%;
	max-width: 100%;
	height: auto;
	padding: 0.25rem;

	@media screen and (min-width: 768px) {
		max-width: 50%;
	}

	@media screen and (min-width: 992px) {
		max-width: 33.333%;
	}

}

.site__gallery__image {
	width: 100%;
	height: auto;
}

.site__footer {
	@include fluid-type($modular_min_width, $modular_max_width, $h6_min, $h6_max);
	color: $gray-600;
	background-color: $gray-900;
	padding: 3rem 0;

	@media screen and (max-width: 576px) {
		padding: 3rem 1rem;
	}
}

.navbar-brand--holder {
	@media screen and (max-width: 768px) {
		justify-content: center;
	}
}

.navbar-brand--footer {
	max-height: 2rem;

	@media screen and (max-width: 768px) {
		display: flex;
		justify-content: center;
		padding: 0;
		max-height: 4rem;
		width: 100%;
	}
}

.nav--footer {
	justify-content: flex-end;

	@media screen and (max-width: 768px) {
		justify-content: center;
		margin-bottom: 0.5rem;
	}

	@media screen and (max-width: 576px) {
		justify-content: space-between;
	}
}

.nav-link--footer {
	font-weight: bold;
	padding: 0 0.5rem 0 0;

	&:hover,
	&:focus {
		border-bottom-color: transparent;
	}

	&::after {
		color: $gray-700;
		content: '/';
		font-weight: normal;
		padding-left: 0.5rem;
	}

	&:last-of-type {
		padding-right: 0;

		&::after {
			content: '';
			padding-left: 0;
		}
	}

	@media screen and (max-width: 576px) {
		width: calc(33.333% - 0.5rem);
		display: flex;
		justify-content: center;
		padding: 0.5rem;
		background-color: rgba($black, 0.2);
		border-radius: $border-radius;
		margin: 0.25rem;

		&:last-of-type {
			padding-right: 0.5rem;
		}

		&::after {
			content: '';
			padding: 0;
		}
	}
}

.footer__copy {
	font-size: 0.85em;
	display: flex;
	list-style-type: none;
	padding: 0;
	justify-content: center;

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
	}

	@media screen and (max-width: 576px) {
		margin-top: 1rem;
	}
}

.footer__copy__item {
	//display: flex;
	width: auto;

	@media screen and (max-width: 768px) {
		text-align: center;
		justify-content: center;
		align-items: center;

		&:first-of-type {
			width: 100%;

			&::after {
				content: '';
				padding: 0;
			}
		}
	}

	&::after {
		content: '•';
		color: $yellow;
		padding: 0 0.5rem;
	}

	&:last-of-type {
		&::after {
			content: '';
			padding: 0;
		}
	}

	@media screen and (max-width: 576px) {
		width: 100%;
		justify-content: center;

		&::after {
			content: '';
			padding: 0;
		}
	}
}

.footer__copy__link {
	border-bottom-color: transparent;

	&:hover,
	&:focus {
		border-bottom-color: transparent;
	}
}

.card-body--frame {
	padding: 1rem;
}

.site__iframe {
	width: 100%;
}

.flex-adjust {
	display: flex;
	flex-flow: column;
}

.flex-adjust-0 {
	order: 0;

	@media screen and (max-width: 992px) {
		order: 1;
		margin-bottom: 4rem;
	}
}

.flex-adjust-1 {
	order: 1;
	margin-top: 4rem;
	align-self: center;
	padding-left: 0;
	padding-right: 0;

	@media screen and (max-width: 992px) {
		order: 0;
		margin-top: 0;
	}
}
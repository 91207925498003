// Modular Headings SASS Mixin
// Original by Chris Coyier
// https://css-tricks.com/snippets/css/fluid-typography/

$modular_min_width: 20rem; //320px;
$modular_max_width: 85.375rem; //1366px;

// Modular scales
$mod_1: 1.067; // Modular scale 1: Minor Second (smallest)
$mod_2: 1.414; // Modular scale 2: Augmented Fourth (largest)

// Heading 1
$h1_min: $mod_1 * $mod_1 * $mod_1 * $mod_1 * 1rem;
$h1_max: $mod_2 * $mod_2 * $mod_2 * $mod_2 * 1rem;

// Heading 2
$h2_min: $mod_1 * $mod_1 * $mod_1 * 1rem;
$h2_max: $mod_2 * $mod_2 * $mod_2 * 1rem;

// Heading 3
$h3_min: $mod_1 * $mod_1 * 1rem;
$h3_max: $mod_2 * $mod_2 * 1rem;

// Heading 4
$h4_min: $mod_1 * 1rem;
$h4_max: $mod_2 * 1rem;

// Heading 5
$h5_min: 1rem;
$h5_max: 1rem;

// Heading 6
$h6_min: (1rem / $mod_1);
$h6_max: (1rem / $mod_2);

.test {
	font-size: $h1_min;
	font-size: $h1_max;

	font-size: $h2_min;
	font-size: $h2_max;

	font-size: $h3_min;
	font-size: $h3_max;

	font-size: $h4_min;
	font-size: $h4_max;

	font-size: $h5_min;
	font-size: $h5_max;

	font-size: $h6_min;
	font-size: $h6_max;
}

h1 {
	@include fluid-type($modular_min_width, $modular_max_width, $h1_min, $h1_max);
}

h2 {
	@include fluid-type($modular_min_width, $modular_max_width, $h2_min, $h2_max);
}

h3 {
	@include fluid-type($modular_min_width, $modular_max_width, $h3_min, $h3_max);
}

h4 {
	@include fluid-type($modular_min_width, $modular_max_width, $h4_min, $h4_max);
}

h5 {
	@include fluid-type($modular_min_width, $modular_max_width, $h5_min, $h5_max);
}

h6 {
	@include fluid-type($modular_min_width, $modular_max_width, $h6_min, $h6_max);
}
// Fluid Type SASS Mixin
// Original by Mike Riethmuller
// https://codepen.io/MadeByMike/pen/vNrvdZ

@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value) {
	& {
		@each $property in $properties {
			#{$property}: $min-value;
		}

		@media screen and (min-width: $min-screen) {
			@each $property in $properties {
				#{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
			}
		}

		@media screen and (min-width: $max-screen) {
			@each $property in $properties {
				#{$property}: $max-value;
			}
		}
	}
}

// Requires the calc-interpolation function which can also be used independently
@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
	$a: ($max-value - $min-value) / ($max-screen - $min-screen);
	$b: $min-value - $a * $min-screen;

	$sign: "+";
	@if ($b < 0) {
		$sign: "-";
		$b: abs($b);
	}
	@return calc(#{$a*100}vw #{$sign} #{$b});
}

$min_width: 320px;
$max_width: 1366px;
$min_font: 16px;
$max_font: 24px;

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if ($u1 == $u2) and ($u1 == $u3) and ($u1 == $u4) {
		& {
			font-size: $min-font-size;

			@media screen and(min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}

			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	} @else {
		@error "There are mixed units, please use the same unit for each parameter: " + $u1 + ", " + $u2 + ", " + $u3 + ", " + $u4;
	}
}


// Single property
html {
	//@include interpolate(font-size, 320px, 1366px, 14px, 20px);
	@include fluid-type($min_width, $max_width, $min_font, $max_font);
}

// Multiple properties with same values
h1,h2,h3,h4,h5 {
	@include interpolate((padding-top, padding-bottom), 20rem, 70rem, 0rem, .5rem);
}
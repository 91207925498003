/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 18, 2018 */

$path: '../fonts/nunito/';

@font-face {
	font-family: 'nunito';
	src: url('#{$path}nunito-bold-webfont.woff2') format('woff2'),
		 url('#{$path}nunito-bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'nunito';
	src: url('#{$path}nunito-bolditalic-webfont.woff2') format('woff2'),
		 url('#{$path}nunito-bolditalic-webfont.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'nunito';
	src: url('#{$path}nunito-italic-webfont.woff2') format('woff2'),
		 url('#{$path}nunito-italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'nunito';
	src: url('#{$path}nunito-regular-webfont.woff2') format('woff2'),
		 url('#{$path}nunito-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
.flowchart {
	font-size: 0.9em;
	line-height: 1.3;
}

.flowchart--wide {
	width: 100%;
	height: 0;
	padding-top: 100%;
	position: relative;

	@media screen and (max-width: 992px) {
		display: none;
	}
}

.flowchart__svg {
	position: absolute;
	top: 0;
	left: 0;
}

.flowchart--narrow {
	@media screen and (min-width: 992px) {
		display: none;
	}
}

.flowchart__title {
	margin: 2rem;
}

.flowchart__footer {
	margin: 2rem;
	text-align: center;
}

.flowchart__steps {
	list-style-type: none;
	padding: 0;

	@supports (display: grid) {
		display: grid;
		grid-gap: 0.5rem;
		grid-template-columns: repeat(8, 1fr);
		grid-template-rows: repeat(13, auto);
		grid-template-areas:
			"mp mp mp mp mp cr cr cr"
			"mp mp mp mp mp mr mr mr"
			"md md md md md mr mr mr"
			"md md md md md mc mc mc"
			"mu mu mu mu mu mc mc mc"
			"mu mu mu mu mu mc mc mc"
			"dc dc dc dc  d  d  d  d"
			"dc dc dc dc  d  d  d  d"
			"cg cg cg cg  d  d  d  d"
			" e  e  e  e  e et et et"
			" e  e  e  e  e et et et"
			" b  b  b  b  b cb cb cb"
			" b  b  b  b  b cb cb cb";

		@media screen and (max-width: 767px) {
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: repeat(7, auto);
			grid-template-areas:
				"mp mp mp cr cr cr"
				"md md md md md md"
				"mr mr mr mu mu mu"
				"mc mc mc dc dc dc"
				" d  d  d  d cg cg"
				" e  e  e et et et"
				" b  b  b  b cb cb";
		}

		@media screen and (max-width: 576px) {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(13, auto);
			grid-template-areas:
				"mp"
				"cr"
				"md"
				"mr"
				"mu"
				"mc"
				"dc"
				" d"
				"cg"
				"et"
				"e"
				" b"
				"cb";
			justify-items: stretch;
		}
	}
}

@supports (display: grid) {
	.flowchart__step--mp { grid-area: mp; } // material-processed
	.flowchart__step--cr { grid-area: cr; } // cardboard-recycled
	.flowchart__step--md { grid-area: md; } // material-depackager
	.flowchart__step--mr { grid-area: mr; } // material-recycled
	.flowchart__step--mu { grid-area: mu; } // material-unrecycled
	.flowchart__step--mc { grid-area: mc; } // material-combined
	.flowchart__step--dc { grid-area: dc; } // dairy-cows
	.flowchart__step--d  { grid-area: d;  } // digester
	.flowchart__step--cg { grid-area: cg; } // crops-growing
	.flowchart__step--e  { grid-area: e;  } // engine
	.flowchart__step--et { grid-area: et; } // electricity-towers
	.flowchart__step--b  { grid-area: b;  } // buildings
	.flowchart__step--cb { grid-area: cb; } // corn-bins
}

.flowchart__step {
	counter-increment: section;
}

.flowchart__panel {
	width: auto;
	background-color: $white;

	@supports (display: grid) {
		background-color: transparent;
		display: grid;
		grid-template-rows: [rA] auto [rB] auto [rC] auto [rD] auto [rE] auto [rF];
		grid-template-columns: [cA] auto [cB] auto [cC] auto [cD] auto [cE] auto [cF];
		align-content: space-around;
		height: 100%;
	}
}

.align-artwork-left {
	.flowchart__artwork__container {
		grid-column-start: cA;
		grid-column-end: cC;
		justify-content: flex-start;
	}

	.flowchart__copy {
		grid-column-start: cC;
		grid-column-end: cF;
	}
}

.align-artwork-center {
	.flowchart__artwork__container {
		grid-column-start: cA;
		grid-column-end: cF;
		max-width: none;
	}

	.flowchart__copy {
		grid-column-start: cB;
		grid-column-end: cE;
	}
}

.align-artwork-right {
	.flowchart__artwork__container {
		grid-column-start: cD;
		grid-column-end: cF;
		justify-content: flex-end;
	}

	.flowchart__copy {
		grid-column-start: cA;
		grid-column-end: cD;
	}
}

.align-artwork-top {
	.flowchart__artwork__container {
		grid-row-start: rA;
		grid-row-end: rC;
		align-items: flex-end;
	}

	.flowchart__copy {
		grid-row-start: rC;
		grid-row-end: rD;
	}
}

.align-artwork-middle {
	.flowchart__artwork__container {
		grid-row-start: rA;
		grid-row-end: rF;
		max-height: none;
	}

	.flowchart__copy {
		grid-row-start: rB;
		grid-row-end: rE;
	}
}

.align-artwork-bottom {
	.flowchart__artwork__container {
		grid-row-start: rD;
		grid-row-end: rF;
		align-self: baseline;
		align-items: flex-start;
	}

	.flowchart__copy {
		grid-row-start: rB;
		grid-row-end: rC;
	}
}

.align-artwork-full {
	.flowchart__artwork__container .flowchart__artwork {
		max-width: 100%;
		width: 100%;
	}

	&.align-artwork-bottom {
		&::before {
			grid-column-start: cA;
			grid-column-end: cD;
		}

		.flowchart__artwork__container {
			grid-column-start: cA;
			grid-column-end: cF;

			&--small {
				grid-column-start: cA;
				grid-column-end: cD;
			}
		}

		.flowchart__copy {
			grid-column-start: cA;
			grid-column-end: cD;
		}
	}

	&.align-artwork-left.align-artwork-bottom {
		&::before {
			grid-column-start: cC;
			grid-column-end: cF;
		}

		.flowchart__copy {
			grid-column-start: cC;
			grid-column-end: cF;
		}

		.flowchart__artwork__container {
			justify-content: flex-start;
			grid-column-start: cA;
			grid-column-end: cD;
		}
	}
}

.flowchart__artwork__container {
	width: auto;
	height: auto;
	max-height: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;

	@media screen and (max-width: 767px) {
		max-height: none;
	}
}

.flowchart__artwork {
	height: auto;
	min-width: 5rem;
	width: 100%;
}

.flowchart__artwork--small {
	max-width: 5rem;
	max-height: 5rem;

	@media screen and (max-width: 767px) {
		max-height: none;
	}
}

.flowchart__artwork--medium {
	max-width: 10rem;
	max-height: 10rem;

	@media screen and (max-width: 767px) {
		max-height: none;
	}
}

.flowchart__artwork--large {
	width: 100%;
	max-width: 15rem;
	max-height: 15rem;

	@media screen and (max-width: 767px) {
		max-height: none;
	}
}

.flowchart__artwork--secondary {
	min-width: 0;
	max-width: 2rem;
}

.flowchart__copy {
	color: $gray-900;
	width: auto;
	max-width: 15rem;
	margin: 0;
	padding: 1rem;
	display: flex;

	@media screen and (min-width: 576px) and (max-width: 767px) {
		flex-wrap: wrap;
	}
}

.flowchart__number {
	&::before {
		display: flex;
		color: $gray-500;
		font-family: $font-headings;
		font-weight: bold;
		@include fluid-type($modular_min_width, $modular_max_width, $h1_min, $h1_max);
		line-height: 1;
		content: counter(section);
		margin-right: 0.75rem;

		@media screen and (min-width: 576px) and (max-width: 767px) {
			margin-bottom: 0.5rem;
		}
	}
}

//  Backgrounds
/// General
.flowchart__panel {
	&::before {
		content: '';
		background-color: $gray-100;
		border-radius: $border-radius * 2;
	}
}

/// Color variations
.flowchart__step {
	&:nth-of-type(even) .flowchart__panel::before {
		background-color: $gray-300;
	}

	&:nth-of-type(4n) .flowchart__panel::before {
		background-color: $white;
	}
}

/// Left
.align-artwork-left::before {
	grid-column-start: cB;
	grid-column-end: cF;
}

/// Center
.align-artwork-center::before {
	grid-column-start: cB;
	grid-column-end: cE;
}

/// Right
.align-artwork-right::before {
	grid-column-start: cA;
	grid-column-end: cE;
}

/// Top
.align-artwork-top::before {
	grid-row-start: rB;
	grid-row-end: rE;
}

/// Middle
.align-artwork-middle::before {
	grid-row-start: rB;
	grid-row-end: rE;
}

/// Bottom
.align-artwork-bottom::before {
	grid-row-start: rB;
	grid-row-end: rE;
}


//  Corner-specific backgrounds
/// Top Left
.align-artwork-left.align-artwork-top {
	&::before {
		grid-row-start: rB;
		grid-row-end: rD;
		grid-column-start: cB;
		grid-column-end: cF;
	}

	.flowchart__copy {
		grid-column-start: cB;
		grid-column-end: cD;
	}

	.flowchart__artwork__container {
		grid-row-start: rA;
		grid-row-end: rC;
		grid-column-start: cA;
		grid-column-end: cD;
		justify-content: flex-start;
	}
}

/// Top Right
.align-artwork-right.align-artwork-top::before {
	grid-column-start: cA;
	grid-column-end: cF;
	grid-row-start: rB;
	grid-row-end: rF;
}

.align-artwork-right.align-artwork-top .flowchart__copy {
	grid-column-start: cA;
	grid-column-end: cF;
	grid-row-start: rC;
	grid-row-end: rF;
}

.align-artwork-right.align-artwork-top .flowchart__artwork__container {
	grid-column-start: cE;
	grid-column-end: cF;
	grid-row-start: rA;
	grid-row-end: rC;
	justify-content: flex-end;
}

/// Bottom Right
.align-artwork-right.align-artwork-bottom {
	&::before {
		grid-column-end: cF;
	}

	.flowchart__copy {
		grid-column-start: cA;
		grid-column-end: cF;
	}

	.flowchart__artwork__container {
		grid-row-start: rC;
		grid-row-end: rF;
		grid-column-start: cC;
		grid-column-end: cF;
	}
}

/// Bottom Left
.align-artwork-left.align-artwork-bottom {
	justify-self: flex-start;

	&::before {
		grid-row-start: rA;
		grid-row-end: rE;
		grid-column-start: cA;
		grid-column-end: cF;
	}

	.flowchart__copy {
		grid-column-start: cA;
		grid-column-end: cF;
	}

	.flowchart__artwork__container {
		grid-column-start: cA;
		grid-column-end: cD;
	}
}